import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import AppIcon from './images/icon.png'

import { SearchBar, VideoList, VideoDetail } from "./components";

import youtube from "./api/youtube";

export default () => {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  return (
    <Grid style={{ justifyContent: "center" }} container spacing={5}>
      <h1 style={{
          paddingTop: 16, 
          paddingBottom: 16,
          alignItems: "center",
          justifyContent: "center",
          borderBottomWidth: 1,
          borderBottomColor: "#ebecf4",
          shadowColor: "#454d65",
          shadowOffset: {height: 5},
          shadowRadius: 15,
          shadowOpacity: 0.2
          }} align='center'>BrouTube</h1>
      <img style={{padding: 10}} align="center" src={AppIcon} alt=""/>
      <Grid item xs={11}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <SearchBar onSubmit={handleSubmit} />
          </Grid>
          <Grid item xs={8}>
            <VideoDetail video={selectedVideo} />
          </Grid>
          <Grid item xs={4}>
            <VideoList videos={videos} onVideoSelect={setSelectedVideo} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  async function handleSubmit(searchTerm) {
    const { data: { items: videos } } = await youtube.get("search", {
      params: {
        part: "snippet",
        maxResults: 5,
        key: process.env.REACT_APP_API_KEY,
        q: searchTerm,
      }
    });

    setVideos(videos);
    setSelectedVideo(videos[0]);
  }
}
